@import "../../index";

@keyframes fade {
  from {
    opacity: 1.0;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1.0;
  }
}

.chat {
  padding:0 2rem;
}

.room {
  background: #fff;
  .room-list {
    padding: 2rem 2rem 0;
    overflow: hidden;
    overflow-y: scroll;
    .room-item {
      &:last-child { margin-bottom: 0;padding-bottom: 1rem }
      margin-bottom: 1rem;
      display: flex;
      .avatar {
        width: 2rem;
        height: 2rem;
        font-size: 2rem;
        line-height: 2rem;
        border-radius: 50%;
      }
      .content {
        margin-left: 1rem;
        width: calc(100% - 2rem);
        .info {
          .name {
            font-weight: bold;
          }
          .tag {
            margin-left: .5rem;
            .badge {
              font-weight: normal;
              font-size:50%;
            }
          }
          .time {
            margin-left: .5rem;
            color: #999
          }
          .dropdown {
            float: right;
            .dropdown-toggle {
              background: none;
              border: 0;
              color: $blue;
              padding: 0;
              &::after { display: none }
            }
            .dropdown-menu {
              left: auto!important;
              right: 0!important;
            }
          }
        }
        .msg {
          margin-top: .5rem;
          line-height: 1.5rem;
          color: #000;
        }
        .attach {
          margin-top: 1rem;
          .attach-item {
            cursor: pointer;
            margin-bottom: 1rem;
            img {
              max-height: 10rem;
              max-width: 100%;
            }
          }
          .attach-item:hover {
            width: 100%;
            height: 100%;
            background: #f6f7fa;
          }
        }
        .reply {
          padding: 1rem 1rem 0 1rem;
          background: #f9f9f9;
          margin-top: 1rem;
          border-radius: .5rem;
        }
      }
    }
  }

  .room-input {
    height: 100px;
    padding: 1rem 2rem;
    display: flex;
    border-top: 1px solid #e9e9e9;
    justify-content: space-between;
    .avatar {
      width: 2rem;
      height: 2rem;
      font-size: 2rem;
      line-height: 2rem;
      border-radius: 50%;
    }
    .login {
      span {
        cursor: pointer;
        color: $blue;
      }
    }
    .input {
      margin-left: 1rem;
      width: calc(100%);
      textarea {
        width: 100%;
        height: 100%;
        border: 0;
      }
      textarea:focus {
        outline: none;
        border: 0;
      }
    }
    .control {
      display: flex;
      width: 5rem;
      flex-direction: column;
      justify-content: right;
      margin-left: 1rem;
      .btn {
        margin-bottom: .5rem;
        width: 4.5rem;
      }
    }
  }
}

.people {
  border-top: 1px solid #e9e9e9;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  .title {
    font-weight: bold;
    span {
      color: $blue;
      border-bottom: 1px solid $blue;
    }
  }
  .people-list {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 2.16rem;
      height: 2.16rem;
      font-size: 2.16rem;
      line-height: 2.16rem;
      border-radius: 50%;
      margin-right: .5rem;
      margin-bottom: .5rem;
      &.typing {animation: fade .6s infinite;}
    }
  }
  .sum {
    float: right;
    font-weight: bold;
    margin-left: auto;
    margin-top: -.5rem;
    span {
      font-weight: normal;
      color: #999
    }
  }
}

.tag {
  .tag-list {
    margin-top: .5rem;
    display: flex;
    flex-wrap: wrap;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: .5rem;
      margin-right: .5rem;
      span {
        border-bottom: 1px solid #e9e9e9;
      }
    }
  }
}

.avatar-selector {
  .title {
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: bold;
    color: #555;
  }
  .avatar-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    .item {
      cursor: pointer;
      &.cat {
        padding: 0;
      }
      &.jojo {
        padding: 0
      }
      &.selected {
        border-width: 2px;
        border-color: $blue;
      }
      box-sizing: border-box;
      overflow: hidden;
      width: 3rem;
      height: 3rem;
      margin-right: .3rem;
      margin-bottom: .3rem;
      border-radius: 50%;
      border:1px solid #ededed;
      padding: .5rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}